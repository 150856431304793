import React, {useContext, useEffect, useState} from "react";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {Badge} from "@mui/material";
import {observer} from "mobx-react-lite";
import {Context} from "..";

const BasketIcon = observer(() => {
    const {user} = useContext(Context);
    const [basketCount, setBasketCount] = useState(0);

    useEffect(() => {
        setBasketCount(user.basketCount);
    },[user.basketCount]);


    return(
        <Badge badgeContent={basketCount} color="greenPrimary">
            <ShoppingBasketIcon />
        </Badge> 
    );
});

export default BasketIcon;