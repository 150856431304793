import {Box, Breadcrumbs, Button, Container, Grid, IconButton, Link, Stack, Typography} from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import {useParams} from "react-router-dom";
import {fetchOneProduct} from "../http/productAPI";
import {NavLink} from 'react-router-dom';
import {SHOP_ROUTE} from "../utils/consts";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {observer} from "mobx-react-lite";
import {addBasket} from "../http/userAPI";
import {Context} from "..";
import FavoriteIcon from '@mui/icons-material/Favorite';

const ProductPage = observer(() => {
    const {user} = useContext(Context);
    const [product, setProduct] = useState({info: []});
    const {id} = useParams();
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={NavLink} to={SHOP_ROUTE}>
          Главная
        </Link>,
        <Typography key="3" color="text.primary">
            {product.name}
        </Typography>
    ];

    
    useEffect(() => {
        fetchOneProduct(id).then(data => setProduct(data));
    }, []);

    const addBask = () => {
        addBasket(product.id).then(data => {
            if(data.basket > 0){
                user.setBasketCount(Number(user.basketCount) + 1);
            }
        });
    };

    return(
        <Box sx={{flexGrow: 1}}>
            <Container sx={{maxWidth:{
                    xl: 'xl', lg: 'lg'
                }, p:3}}>
                <Stack spacing={2} sx={{mt: 2}} >
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">        
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                <Typography variant="h1" sx={{fontSize: 30, fontWeight: 'bold',mt:1}}>{product.name}</Typography>
                <Typography variant="body2">Код товара: {product.id}</Typography>
                <Grid container sx={{mt:1}}>
                    <Grid xs item >
                        <img src={product.img} alt={product.name}/>
                    </Grid>
                    <Grid xs item sx={{p:3}}>
                        <Typography variant="body1">Описание: {product.description}</Typography>
                        {product.info.length > 0 ? <Typography variant="h6">Характеристики</Typography> : null}
                        {product.info.map(info => 
                        <Grid container key={info.number} sx={{mt:1}}>
                            <Grid item sx={{color: '#777'}}>{info.title}:</Grid>
                            <Grid item>{info.description}</Grid>
                        </Grid>
                    )}
                    </Grid>
                    <Grid xs item>
                        <Grid item container sx={{backgroundColor: '#e5e5e5', borderRadius: 2, p:3}} direction="column">

                        <Typography variant="body1" sx={{color:'#555'}}>Цена в Вологде:</Typography>
                        <Typography variant="h5">{product.price} ₽</Typography>
                        <Grid container sx={{mt:2}}>
                            <Grid item xs>
                            <Button
                                variant="contained" 
                                color="greenPrimary" 
                                sx={{fontSize:'1rem'}}
                                onClick={addBask}
                            >Купить</Button>
                            </Grid>
                            <Grid item xs>
                            <IconButton aria-label="add to favorites">
                                <FavoriteIcon />
                            </IconButton> <Link underline="hover" key="1" color="inherit">В закладки</Link>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
});

export default ProductPage;