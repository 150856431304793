import {makeAutoObservable} from "mobx";

export default class UserStore {
    constructor () {
        this._isAuth = false;
        this._user = {};
        this._role = '';
        this._guest = '';
        this._basket = [];
        this._shipping = [];
        this._zakaz = [];
        this._basketCount = 0;
        makeAutoObservable(this);
    }

    setIsAuth(bool){
        this._isAuth = bool;
    }

    setUser(user){
        this._user = user;
    }

    setRole(role){
        this._role = role;
    }

    setBasket(basket){
        this._basket = basket;
    }

    setBasketCount(basketCount){
        this._basketCount = basketCount;
    }

    setGuest(guest){
        this._guest = guest;
    }

    setShipping(shipping){
        this._shipping = shipping;
    }

    setZakaz(zakaz){
        this._zakaz = zakaz;
    }

    get isAuth(){
        return this._isAuth;
    }

    get user(){
        return this._user;
    }

    get role(){
        return this._role;
    }

    get basket(){
        return this._basket;
    }

    get basketCount(){
        return this._basketCount;
    }

    get guest(){
        return this._guest;
    }

    get shipping(){
        return this._shipping;
    }

    get zakaz(){
        return this._zakaz;
    }
}