import {observer} from "mobx-react-lite";
import React, {useContext, useState} from "react";
import {NavLink} from 'react-router-dom';
import {Context} from "..";
import {BASKET_ROUTE, SHOP_ROUTE, ZAKAZ_LIST_ROUTE} from "../utils/consts";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROUTE, LOGIN_ROUTE} from "../utils/consts";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Container, Divider, IconButton, Menu, MenuItem} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import PhishingIcon from '@mui/icons-material/Phishing';
import MenuIcon from '@mui/icons-material/Menu';
import BasketIcon from "./BasketIcon";



const NavBar = observer(() => {
    const {user} = useContext(Context);
    const {product} = useContext(Context);

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const pages = ['Новости', 'Рецепты','Доставка и оплата', 'О нас', 'Контакты'];

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const handleClose = () => {
    setAnchorEl(null);
    };  

    const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const logOut = () => {
        user.setUser({});
        user.setIsAuth(false);
        user.setBasketCount(0);
        localStorage.removeItem('token');
        handleClose();
    };    



    return(

        <Box sx={{flexGrow: 1}}>
            <AppBar 
            position="static"
            >
                <Container
                sx={{maxWidth:{xl: 'xl', lg: 'lg'}}}
                >
                    <Toolbar>
                        <PhishingIcon sx={{display: {xs: 'none', md: 'flex'}, mr: 1}} />
                        <Typography
                            variant="h6"
                            noWrap
                            component={NavLink} to={SHOP_ROUTE}
                            sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                           SevFish
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                            >
                            {product.category.map(type =>
                                <MenuItem key={type.id} 
                                onClick={() => {
                                    // eslint-disable-next-line
                                    type.id === product.selectedCategory.id ? product.setSelectedCategory([]) : product.setSelectedCategory(type);
                                    handleCloseNavMenu();
                                }}
                                >
                                    <Typography textAlign="center">{type.name}</Typography>
                                </MenuItem>  

                            )}    
                            <Divider/>
                            {pages.map((page, i) => (
                                <MenuItem key={i} onClick={handleCloseNavMenu}>
                                <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                            </Menu>
                        </Box>
                        <PhishingIcon sx={{display: {xs: 'flex', md: 'none'}, mr: 1}} />
                        <Typography
                            variant="h5"
                            noWrap
                            component={NavLink} to={SHOP_ROUTE}
                            sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                            }}
                        >
                            SevFish
                        </Typography>
                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            
                            {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{my: 2, color: 'white', display: 'block'}}
                            >
                                {page}
                            </Button>
                            ))}
                        </Box>

                        
                            
                            <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={() => navigate(BASKET_ROUTE)}
                                >
                                <BasketIcon/>    
                            </IconButton>    
                            
                        {user.isAuth ? (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={handleMenu}
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    sx={{mt:5}}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem onClick={() => {navigate(SHOP_ROUTE); handleClose();}}>Главная страница</MenuItem>
                                    {user.role === "ADMIN" ? <MenuItem>
                                        <a href={ADMIN_ROUTE}>Управление сайтом</a>
                                        </MenuItem> : null}
                                    <MenuItem onClick={() => {navigate(ZAKAZ_LIST_ROUTE); handleClose();}}>Мои заказы</MenuItem>    
                                    <MenuItem onClick={() => logOut()}>Выйти</MenuItem>
                                </Menu>
                            </div>
                        )
                        :
                        (
                            <Button onClick={() => navigate(LOGIN_ROUTE)} color="inherit">Авторизация</Button>
                        )
                        }
                        
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
        
        
    );
});

export default NavBar;