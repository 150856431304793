import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import {Box, List, ListItem} from "@mui/material";

const style = {
    width: '100%',
    bgcolor: 'background.paper',
    borderRadius:3
  };

const CategoryBar = observer(() => {
    const {product} = useContext(Context);
    return(
        <Box boxShadow={3} sx={{borderRadius:3}}>
            <List sx={style} component="nav" aria-label="mailbox folders">
                {product.category.map(type =>
                    <ListItem sx={{p:1.5}} button 
                    selected={type.id === product.selectedCategory.id ? true : false}
                    onClick={() => {
                        // eslint-disable-next-line
                        type.id === product.selectedCategory.id ? product.setSelectedCategory([]) : product.setSelectedCategory(type);
                    }}
                    key={type.id}
                    >{type.name}</ListItem>  

                )}
            </List>
        </Box>
    );
});

export default CategoryBar;