import {$host} from ".";
// eslint-disable-next-line
import jwt_decode from "jwt-decode";


export const fetchCategory = async () => {
    const {data} = await $host.get('api/category.php'); 
    return data;
};

export const fetchType = async () => {
    const {data} = await $host.get('api/type.php'); 
    return data;
};

export const fetchProducts = async (categoryId, typeId, page, limit  = 1) => {
    const {data} = await $host.get('api/products.php', {params: {
        categoryId, typeId, page, limit
    }}); 
    return data;
};

export const fetchOneProduct = async (id) => {
    const {data} = await $host.get('api/oneProduct.php?getProduct=' + id); 
    return data;
};