import Basket from './pages/Basket';
import Shop from './pages/Shop';
import Auth from './pages/Auth';
import ProductPage from './pages/ProductPage';
import {BASKET_ROUTE, BASKET_STATUS_ROUTE, LOGIN_ROUTE, PRODUCT_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE, ZAKAZ_LIST_ROUTE} from "./utils/consts";
import BasketStatus from './pages/BasketStatus';
import ZakazList from './pages/ZakazList';

export const authRoutes = [
    {
        path: ZAKAZ_LIST_ROUTE,
        Component: ZakazList
    }
];

export const publicRoutes = [
    {
        path: SHOP_ROUTE,
        Component: Shop
    },
    {
        path: LOGIN_ROUTE,
        Component: Auth
    },
    {
        path: REGISTRATION_ROUTE,
        Component: Auth
    },
    {
        path: PRODUCT_ROUTE + '/:id',
        Component: ProductPage
    },
    {
        path: BASKET_ROUTE,
        Component: Basket
    },
    {
        path: BASKET_STATUS_ROUTE + '/:id',
        Component: BasketStatus
    }
];