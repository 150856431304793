import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import {Pagination, Stack} from "@mui/material";

const Pages = observer(() => {
    const {product} = useContext(Context);
    const pageCount = Math.ceil(product.totalCount / product.limit);
    const pages = [];



    for (let i = 0; i < pageCount; i++){
        pages.push(i + 1);
    }

    const handleChange = (event, value) => {
        product.setPage(value);
      };

    return(

            <Stack sx={{pl:2}}>
                <Pagination count={pageCount} onChange={handleChange} variant="outlined" shape="rounded" />
            </Stack>
    );
});

export default Pages;