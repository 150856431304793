import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {PRODUCT_ROUTE} from "../utils/consts";
import PropTypes from 'prop-types';
import {Card, CardMedia, CardContent, Typography, IconButton, CardActions, Button, Grid, Stack} from "@mui/material";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {addBasket} from "../http/userAPI";
import {Context} from "..";

const ProductItem = ({prod}) => {
    const {user} = useContext(Context);
    ProductItem.propTypes = {
        prod: PropTypes.object,
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        img: PropTypes.string
    };

    const navigate = useNavigate();


    const addBask = () => {
        addBasket(prod.id).then(data => {
            if(data.basket > 0){
                user.setBasketCount(Number(user.basketCount) + 1);
            }
        });
    };

    return (
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
            <Stack alignItems="center" justifyContent="space-between">
                <Card raised sx={{width: 250, m: 1.5}} key={prod.id}>    
                    <CardMedia 
                        component="img"
                        height="225"
                        image={prod.img}
                        alt={prod.name}
                        sx={{maxHeight: 150, cursor: 'pointer'}}
                        onClick={() => navigate(PRODUCT_ROUTE + '/' + prod.id)}
                    />       
                    <CardContent sx={{minHeight:100}}>
                        <Typography sx={{cursor: 'pointer'}} gutterBottom variant="h6" component="div" onClick={() => navigate(PRODUCT_ROUTE + '/' + prod.id)}>
                            {prod.name}
                        </Typography>
                        <Typography gutterBottom variant="body2" component="div">
                            Краткое описание, одна или две строки
                        </Typography>
                    </CardContent>
                    <CardContent sx={{pb:0, pt: 0}}>
                        <Typography gutterBottom variant="body1" component="div" sx={{fontWeight: 'bold'}} alignItems="center">
                            {prod.price}<CurrencyRubleIcon sx={{fontSize:14,fontWeight: 'bold'}} />
                        </Typography>
                    </CardContent>
                    <CardActions disableSpacing sx={{justifyContent: 'space-between'}}>
                        <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <Button 
                        variant="contained" 
                        color="greenPrimary" 
                        sx={{fontSize:'1rem'}}
                        onClick={addBask}
                        >Купить</Button>
                    </CardActions>
                </Card>
            </Stack>
        </Grid>
    );
};


export default ProductItem;