import {Box, Container, Grid, Typography} from "@mui/material";
import React from "react";
import PhishingIcon from '@mui/icons-material/Phishing';
import {NavLink} from "react-router-dom";
import {SHOP_ROUTE} from "../utils/consts";

const Footer = () => {
    return(
        <Box sx={{flexGrow: 1, backgroundColor:'#3a2222', mt:1}}>
            <Container
                sx={{maxWidth:{xl: 'xl', lg: 'lg'}}}
                >
                <Grid container sx={{color:'#e1e1e1', pt:4, pb:3}} justifyContent="space-between">
                    <Grid item xs>
                        <Grid container alignItems="center">
                            <Grid item>
                                <PhishingIcon sx={{mr: 1}} />
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component={NavLink} to={SHOP_ROUTE}
                                    sx={{
                                    mr: 2,
                                    fontFamily: 'monospace',
                                    fontWeight: 700,
                                    flexGrow: 1,
                                    letterSpacing: '.3rem',
                                    color: 'inherit',
                                    textDecoration: 'none',
                                    }}
                                >
                                SevFish
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1">
                            О магазине
                        </Typography>
                        <Typography variant="body1">
                            Новости
                        </Typography>
                        <Typography variant="body1">
                            Доставка и оплата
                        </Typography>
                        <Typography variant="body1">
                            Контакты
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1">
                            О магазине
                        </Typography>
                        <Typography variant="body1">
                            Новости
                        </Typography>
                        <Typography variant="body1">
                            Доставка и оплата
                        </Typography>
                        <Typography variant="body1">
                            Контакты
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="body1">
                            О магазине
                        </Typography>
                        <Typography variant="body1">
                            Новости
                        </Typography>
                        <Typography variant="body1">
                            Доставка и оплата
                        </Typography>
                        <Typography variant="body1">
                            Контакты
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;