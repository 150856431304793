import {Box, Breadcrumbs, Container, Grid, Paper, Stack, Typography, Link} from "@mui/material";
import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {fetchAllZakaz} from "../http/userAPI";
import {NavLink, useNavigate} from "react-router-dom";
import {BASKET_STATUS_ROUTE, SHOP_ROUTE} from "../utils/consts";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const ZakazList = observer(() => {
    const navigate = useNavigate();
    const [zakaz, setZakaz] = useState({});

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={NavLink} to={SHOP_ROUTE}>
            Главная
        </Link>,
        <Typography key="3" color="text.primary">
            Мои заказы
        </Typography>
    ];

    useEffect(() => {
        fetchAllZakaz().then(data => setZakaz(data));
    }, []);

    const zakazPays = status => {
        switch(status){
            case '1':
                return 'Банковской картой при получении';
            case '2':
                return 'Банковской картой онлайн';
            default:
                return 'Наличными при получении';
        }

    };

    return(
        <Box sx={{flexGrow: 1}}>
            <Container sx={{maxWidth:{
                    xl: 'xl', lg: 'lg'
                }, p:3}}>
                <Stack spacing={2} sx={{mt: 2}} >
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">        
                        {breadcrumbs}
                    </Breadcrumbs>
                </Stack>
                <Typography variant="h4">Мои заказы</Typography>
                    <Box>
                        <Grid container sx={{minHeight:'75px', flexWrap:'wrap', alignItems: 'center'}} >
                                <Grid xs sx={{textAlign: 'center', minWidth:'70px'}} item>Номер заказа</Grid>
                                <Grid xs sx={{cursor: 'pointer'}} zeroMinWidth item>Дата заказа</Grid>
                                <Grid xs item sx={{minWidth:'70px',pl:1,pr:1}}>Состояние</Grid>
                                <Grid xs item sx={{minWidth:'70px',pl:1,pr:1}}>Вид оплаты</Grid>
                                <Grid xs item sx={{textAlign: 'right', minWidth:'140px',pr:3}}>Сумма заказа</Grid>
                        </Grid>
                    </Box>
                    <Paper elevation={3} square sx={{mr:1, mb:2}}>
                        {zakaz.length > 0 ? zakaz.map((zakaz, i) => 
                                <Grid key={zakaz.id} container sx={{minHeight:'75px', flexWrap:'wrap', alignItems: 'center', backgroundColor: i % 2 == 0 ? '#dfdfdf' : '#fff'}} >
                                        <Grid xs sx={{textAlign: 'center', minWidth:'70px'}} item onClick={() => navigate(BASKET_STATUS_ROUTE + '/' + zakaz.id)}>{zakaz.id}</Grid>
                                        <Grid xs sx={{cursor: 'pointer'}} zeroMinWidth item onClick={() => navigate(BASKET_STATUS_ROUTE + '/' + zakaz.id)}>{new Date(Number(zakaz.time*1000)).toLocaleString()}</Grid>
                                        <Grid xs item sx={{minWidth:'70px',pl:1,pr:1, color: 'green'}}>{zakaz.status}</Grid>
                                        <Grid xs item sx={{minWidth:'70px',pl:1,pr:1}}>{zakazPays(zakaz.pay)}</Grid>
                                        <Grid xs item sx={{textAlign: 'right', minWidth:'140px',pr:3}}>{zakaz.summItem} ₽</Grid>
                                </Grid>
                        )
                        :
                        null
                        }
                    </Paper>
            </Container>
        </Box>
    );
});

export default ZakazList;