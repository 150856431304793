import React, {useContext, useState} from "react";
import {LOGIN_ROUTE, REGISTRATION_ROUTE, SHOP_ROUTE} from "../utils/consts";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {login, registration} from "../http/userAPI";
import {Context} from "..";
import {Card, CardContent, Grid, Typography, TextField, Button, Stack} from "@mui/material";


const Auth = observer(() => {
    const {user} = useContext(Context);
    const location = useLocation();
    const navigate = useNavigate();
    const isLogin = location.pathname === LOGIN_ROUTE;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    const click = async () => {
        try {
            let data;
            if (isLogin) {
                data = await login(email, password);
            } else {
                data = await registration(email, password);
                if(data) {
                    //data
                }
            }
            user.setUser(user);
            user.setRole(data.data.role);
            user.setIsAuth(true);
            user.setBasketCount(localStorage.getItem('basketCount'));
            navigate(SHOP_ROUTE);
        } catch (e) {
            alert(e.response.data.message);
        }
    };
    

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '90vh'}}
        >
            <Grid item xs={3}>
                <Card sx={{
                    padding:3,
                    minWidth:{
                        xs:300,
                        md:600
                    }
                }} raised>
                    <CardContent>
                        <Typography variant="h4">{isLogin ? 'Авторизация' : 'Регистрация'}</Typography>
                    </CardContent>
                    
                    <CardContent>
                        <TextField color="neutral" sx={{mb: 1.5}} fullWidth label="Введите e-mail..." variant="outlined" value={email} onChange={e => setEmail(e.target.value)} />
                        <TextField color="neutral" fullWidth  type="password" label="Введите пароль..." variant="outlined" value={password} onChange={e => setPassword(e.target.value)} />
                    </CardContent>

                    <Grid container justifyContent="space-between" sx={{padding: 2,
                    direction:{
                        xs: 'column',
                        md: 'row'
                    }}}>
                        <Grid item xs="auto" sx={{mb:1.5}}>
                            {isLogin ?
                            <div>
                                Нет аккаунта? <Button color="greenPrimary" component={NavLink} to={REGISTRATION_ROUTE}>Зарегистрируйтесь</Button>
                            </div>
                            :
                            <div>
                                Есть аккаунт? <Button color="greenPrimary" component={NavLink} to={LOGIN_ROUTE}>Войдите</Button>
                            </div>
                            }
                        </Grid>
                        <Grid item xs="auto" sx={{width: '100%'}}>
                            <Stack alignItems="center" justifyContent="space-between">
                            {isLogin ? <Button sx={{width: '100%'}} color="greenPrimary" variant="outlined"
                            onClick={click}
                            >Войти</Button> : 
                            <Button sx={{width: '100%'}} color="greenPrimary" variant="outlined"
                            onClick={click}
                            >Зарегистрироваться</Button>}
                            </Stack>
                        </Grid>
                    </Grid>
                    
                
                </Card>
            </Grid>
        </Grid>
        
    );
});

export default Auth;