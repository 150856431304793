import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ProductStore from './store/ProductStore';
import UserStore from './store/UserStore';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './index.scss';

export const Context = createContext(null);

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#801313',
      darker: '#590d0d',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    greenPrimary: {
      main: '#2e7d32',
      darker: '#205723',
      contrastText: '#fff',
    },
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context.Provider value={{
    user: new UserStore(),
    product: new ProductStore()
  }}>
      <ThemeProvider theme={theme}>
          <App />
      </ThemeProvider>
  </Context.Provider>
);