import React from "react";
import Skeleton from '@mui/material/Skeleton';
import {Card, CardActions, CardContent, Grid, Stack} from "@mui/material";

const ProductListSkeleton = () => {
    return(
        <Grid sx={{p:1}} container spacing={{xs: 1, sm: 1, md: 3, lg: 3, xl: 3}} columns={{xs: 1, sm: 2, md: 2, lg: 3, xl: 4}}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised={true} sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised={true} sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised={true} sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised={true} sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <Stack alignItems="center" justifyContent="space-between">
                    <Card raised={true} sx={{width: 250, m: 1.5}}>
                        <Skeleton variant="rectangular" width={250} height={150} />
                        <CardContent sx={{minHeight:100, pb: 0}}>
                            <Skeleton height={35} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:0.75}} />
                            <Skeleton sx={{mt:1.4}} width={75} height={35}/>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'space-between', m: 1, mt: 0, pt: 0, pb:0}}>
                            <Skeleton width={35} height={50} />
                            <Skeleton width={100} height={50} />
                        </CardActions>
                    </Card>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default ProductListSkeleton;