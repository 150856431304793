import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import {Chip, Box} from "@mui/material";

const TypeBar = observer(() => {
    const {product} = useContext(Context);
    return (
        <Box className="typeBar__row" sx={{pl:2}}>
            {product.types.map(type => 
                <Chip label={type.name}
                key={type.id}
                variant={type.id === product.selectedType.id ? 'outlined' : undefined}
                onClick={() => {
                    // eslint-disable-next-line
                    type.id === product.selectedType.id ? product.setSelectedType([]) : product.setSelectedType(type);
                }}
                />
            )}
        </Box>
    );
});

export default TypeBar;