import React, {useContext} from "react";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import ProductItem from "./ProductItem";
import {Grid} from "@mui/material";


const ProductList = observer(() => {
    const {product} = useContext(Context);
    return(
            <Grid sx={{p:1}} container spacing={{xs: 1, sm: 1, md: 3, lg: 3, xl: 3}} columns={{xs: 1, sm: 2, md: 2, lg: 3, xl: 4}}>
            {product.products.map(prod => 
                <ProductItem key={prod.id} prod={prod}/>
            )}
            </Grid>
    );
});

export default ProductList;