import {Box, Breadcrumbs, Container, Grid, Paper, Stack, Step, StepLabel, Stepper, Typography, Link} from "@mui/material";
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "..";
import {fetchOneZakaz} from "../http/userAPI";
import {useNavigate, useParams, NavLink} from "react-router-dom";
import {PRODUCT_ROUTE, SHOP_ROUTE, ZAKAZ_LIST_ROUTE} from "../utils/consts";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const BasketStatus = observer(() => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {user} = useContext(Context);
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('');
    const [pays, setPays] = useState('Наличными при получении');
    const labelProps = {};

    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" component={NavLink} to={SHOP_ROUTE}>
            Главная
        </Link>,
        user.isAuth ? <Link underline="hover" key="1" color="inherit" component={NavLink} to={ZAKAZ_LIST_ROUTE}>
        Мои заказы
        </Link> : null,
        <Typography key="3" color="text.primary">
            Статус заказа #{id}
        </Typography>
    ];

    useEffect(() => {
        if(user.zakaz.length === 0 || (user.zakaz.id > 0 && user.zakaz.id != id)) fetchOneZakaz(id).then(data => user.setZakaz(data));
        if(user.zakaz.time > 0) setDate(new Date(Number(user.zakaz.time*1000)).toLocaleString());
        if(user.zakaz.status) {
            setStatus(user.zakaz.status.map(status => {
                if(status.statusComments){
                    labelProps.optional = (
                        <Typography variant="caption">{status.statusComments}</Typography>
                    );
                }
                return(
                    <Step key={status.statusTime}>
                        <StepLabel {...labelProps}>{status.statusName} {new Date(Number(status.statusTime*1000)).toLocaleString()}</StepLabel>
                    </Step>
                );
            }));
        }
        if(user.zakaz.pay){
            switch(user.zakaz.pay){
                case '1':
                    setPays('Банковской картой при получении');
                    break;
                case '2':
                    setPays('Банковской картой онлайн');
                    break;
                default:
                    setPays('Наличными при получении');
                    break;
            }
        }
    }, [user.zakaz]);




    return(
        <Box sx={{flexGrow: 1}}>
            <Container sx={{maxWidth:{
                    xl: 'xl', lg: 'lg'
                }, p:3}}>
                    <Stack spacing={2} sx={{mt: 2}} >
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb">        
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                    <Typography variant="h5">
                        Статус заказа #{id} от {date}
                    </Typography>
                    <Grid container spacing={1} sx={{mt:1}}>
                        <Grid item sx={{backgroundColor: '#efefef', borderRadius:2, p:1, m:1}}>
                            {status ? 
                            <Stepper orientation="vertical" activeStep={user.zakaz.status.length-1}>
                                {status}
                            </Stepper>
                            :
                            null
                            }
                        </Grid>
                        <Grid item container xs sx={{backgroundColor: '#efefef', borderRadius:2, p:1, m:1}}>
                            <Grid container item>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Имя</Typography><Typography variant="body2">{user.zakaz.name}</Typography></Grid>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Телефон</Typography><Typography variant="body2">{user.zakaz.phone}</Typography></Grid>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Адрес</Typography><Typography variant="body2">{user.zakaz.address}</Typography></Grid>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Комментарий</Typography><Typography variant="body2">{user.zakaz.comments}</Typography></Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Сумма заказа</Typography><Typography variant="body2">{user.zakaz.summItem} ₽</Typography></Grid>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Наименований</Typography><Typography variant="body2">{user.zakaz.countItem}</Typography></Grid>
                                <Grid item sx={{p:1, pr:2}}><Typography variant="body1">Способ оплаты</Typography><Typography variant="body2">{pays}</Typography></Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Typography variant="h6">
                        Состав заказа
                    </Typography>
                    <Paper elevation={3} square sx={{mr:1, mt:2, mb:2}}>
                        {user.zakaz.items ? user.zakaz.items.map((items, i) => 
                            <Grid key={items.id} container sx={{minHeight:'75px', flexWrap:'wrap', alignItems: 'center', backgroundColor: i % 2 == 0 ? '#dfdfdf' : '#fff'}} >
                                    <Grid xs={1} sx={{textAlign: 'center', minWidth:'70px'}} item>{i+1}</Grid>
                                    <Grid xs={1} sx={{textAlign: 'center', minWidth:'70px', cursor: 'pointer'}} item onClick={() => navigate(PRODUCT_ROUTE + '/' + items.prod_id)}><img src={items.img} alt={items.name} style={{maxWidth: '50px', height: 'auto', borderRadius: '25px'}} /></Grid>
                                    <Grid xs={6} sx={{cursor: 'pointer'}} zeroMinWidth item onClick={() => navigate(PRODUCT_ROUTE + '/' + items.prod_id)}>{items.name}</Grid>
                                    <Grid xs={1} item sx={{minWidth:'70px',pl:1,pr:1}}>{items.count}</Grid>
                                    <Grid xs={2} item sx={{textAlign: 'right', minWidth:'140px',pr:3}}>{items.price*items.count} ₽</Grid>
                            </Grid>
                        )
                        :
                        null
                        }
                    </Paper>

            </Container>
        </Box>
    );
});

export default BasketStatus;