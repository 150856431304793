import {makeAutoObservable} from "mobx";

export default class ProductStore{
    constructor(){
        this._category = [];
        this._types = [];
        this._products = [];
        this._selectedCategory = {};
        this._selectedType = {};
        this._page = 1;
        this._totalCount = 0;
        this._limit = 1;
        makeAutoObservable(this);
    }

    setCategory(category){
        this._category = category;
    }

    setTypes(types){
        this._types = types;
    }

    setProducts(products){
        this._products = products;
    }

    setSelectedCategory(category){
        this.setPage(1);
        this._selectedCategory = category;
    }

    setSelectedType(type){
        this.setPage(1);
        this._selectedType = type;
    }
    setPage(page){
        this._page = page;
    }
    setTotalCount(totalCount){
        this._totalCount = totalCount;
    }
    setLimit(limit){
        this._limit = limit;
    }


    get category(){
        return this._category;
    }

    get types(){
        return this._types;
    }

    get products(){
        return this._products;
    }

    get selectedCategory(){
        return this._selectedCategory;
    }

    get selectedType(){
        return this._selectedType;
    }

    get page(){
        return this._page;
    }

    get totalCount(){
        return this._totalCount;
    }

    get limit(){
        return this._limit;
    }
}