import React, {useContext, useEffect, useState} from "react";
import CategoryBar from "../components/CategoryBar";
import ProductList from "../components/ProductList";
import TypeBar from "../components/TypeBar";
import {observer} from "mobx-react-lite";
import {Context} from "..";
import {fetchCategory, fetchProducts, fetchType} from "../http/productAPI";
import Pages from "../components/Pages";
import ProductListSkeleton from "../components/ProductListSkeleton";
import {Box, Container, Grid} from "@mui/material";

const Shop = observer(() => {
    const {product} = useContext(Context);
    const [prodLoading, setProdLoading] = useState(true);

    useEffect(() => {
        fetchCategory().then(data => product.setCategory(data));
        fetchType().then(data => product.setTypes(data));
        const timeout = setTimeout(() => {
            fetchProducts(null,null,1,8).then(data => {
                product.setProducts(data.rows);
                product.setTotalCount(data.count);
                setProdLoading(false);
            });
          }, 1000);

          return () => {
            // clears timeout before running the new effect
            clearTimeout(timeout);
          };
    }, []);

    useEffect(() => {
        setProdLoading(true);
        const timeout = setTimeout(() => {
            fetchProducts(product.selectedCategory.id,product.selectedType.id,product.page,8).then(data => {
                product.setProducts(data.rows);
                product.setTotalCount(data.count);
                setProdLoading(false);
            });
          }, 1000);
          return () => {
            // clears timeout before running the new effect
            clearTimeout(timeout);
          };

    },[product.page, product.selectedCategory, product.selectedType]);

    return(
        <Box sx={{flexGrow: 1}}>
            <Container sx={{maxWidth:{
                    xl: 'xl', lg: 'lg'
                }, p:3}}>
                <Grid container sx={{pl:3,pr:3}} columns={{xs: 1, md: 4, lg: 5, xl: 5}}>
                    <Grid item xs={1} md={1} lg={1} xl={1} sx={{display:{xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}}>
                        <CategoryBar/>
                    </Grid>
                    <Grid item xs={1} md={3} lg={4} xl={4}>
                        <TypeBar/>
                        {!prodLoading ? <ProductList/> : <ProductListSkeleton/>}
                        <Pages/>
                    </Grid>
                </Grid>    
            </Container>
        </Box>
    );
});

export default Shop;