import {$authHost, $host} from ".";
// eslint-disable-next-line
import jwt_decode from "jwt-decode";


export const registration = async (email, password) => {
    const {data} = await $host.post('api/create_user.php', {email, password, role: 'user'});
    localStorage.setItem('token', data.token);
    return jwt_decode(data.token);
};

export const login = async (email, password) => {
    const guest = localStorage.getItem('guest');
    const {data} = await $host.post('api/login.php', {email, password, guest: guest});
    localStorage.setItem('token', data.token);  
    localStorage.setItem('basketCount', data.basketCount);
    return jwt_decode(data.token);
};

export const check = async () => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/check.php', {guest: guest});
    localStorage.setItem('token', data.token); 
    localStorage.setItem('basketCount', data.basketCount);
    return jwt_decode(data.token);
};

export const addBasket = async (basket) => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/add_basket.php', {basket, guest: guest});
    return data;
};

export const fetchBasket = async () => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/get_basket.php', {guest: guest}); 
    return data;
};

export const fetchOneZakaz = async (id) => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/get_one_zakaz.php', {id, guest: guest}); 
    return data;
};

export const fetchAllZakaz = async () => {
    const {data} = await $authHost.get('api/get_all_zakaz.php'); 
    return data;
};

export const deleteItemBasket = async (item) => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/delete_item_basket.php', {item, guest: guest}); 
    return data;
};

export const updateItemBasket = async (item, value) => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/update_item_basket.php', {item, value, guest: guest}); 
    return data;
};

export const createZakaz = async (zakaz) => {
    const {data} = await $authHost.post('api/create_zakaz.php', zakaz);
    return data;
};

export const fetchShipping = async () => {
    const guest = localStorage.getItem('guest');
    const {data} = await $authHost.post('api/get_shipping.php', {guest: guest}); 
    return data;
};