import {Grid, IconButton, Paper, TextField, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "..";
import {deleteItemBasket, fetchBasket, updateItemBasket} from "../http/userAPI";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';

const BasketItem = observer(() => {
    const {user} = useContext(Context);
    const [timer, setTimer] = useState(0);
    const [send, setSend] = useState(0);
    const [number, setNumber] = useState(0);
    const [value, setValue] = useState(0);
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        fetchBasket().then(data => user.setBasket(data));
    }, []);

    useEffect(() => {
        if(timer === send && value > 0 && number > 0) {
            updateItemBasket(number, value).then(data => {
                user.setBasket(data);
                user.setBasketCount(data.length);
            });
            setUpdate(false);
        }
    },[timer, send]);

    const changeInfo = (value, number) => {
        if(value < 1) value = 1;
        if(value > 30) value = 30;
        user.setBasket(user.basket.map(i => i.id === number ? {...i, ['count']: value} : i));
        const now = new Date();
        const time = now.getTime();
        setNumber(number);
        setTimeout(() => {
            setValue(value);
            setSend(time);
        }, 350);
        setTimer(time);
        setUpdate(true);
    };

    const deleteItem = id => {
        deleteItemBasket(id).then(data => {
            user.setBasket(data);
            user.setBasketCount(data.length);
        });
    };

    return(

        <Paper elevation={3} square sx={{mt:2, mb:2}}>
            {user.basket.map((basket, i) => 
                <Grid key={basket.id} container sx={{minHeight:'75px', flexWrap:'wrap', alignItems: 'center', backgroundColor: i % 2 == 0 ? '#dfdfdf' : '#fff'}} >
                        <Grid xs={1} sx={{textAlign: 'center', minWidth:'70px'}} item>{i+1}</Grid>
                        <Grid xs={1} sx={{textAlign: 'center', minWidth:'70px'}} item><img src={basket.img} alt={basket.name} style={{maxWidth: '50px', height: 'auto', borderRadius: '25px'}} /></Grid>
                        <Grid xs={6} zeroMinWidth item>{basket.name}</Grid>
                        <Grid xs={1} item sx={{minWidth:'70px',pl:1,pr:1}}>
                            <TextField 
                            value={basket.count}
                            onChange={(e) => changeInfo(e.target.value, basket.id)} 
                            inputProps={{type: 'number', style: {textAlign: 'center'}}}
                            variant="standard" 
                            size="small"
                            color="greenPrimary"
                            autoComplete="off"
                            />
                            {basket.count > 1 ? 
                            <Typography variant="body2" sx={{textAlign:'center',color:'#777',pt:1}}>
                                {basket.price} ₽/шт.
                            </Typography>
                            : null}
                        </Grid>
                        <Grid xs={2} item sx={{textAlign: 'right', minWidth:'140px',pr:3}}>
                            {update && number === basket.id ? <CircularProgress color="neutral" /> : `${basket.price*basket.count} ₽`}
                        </Grid>
                        <Grid xs={1} sx={{textAlign: 'center'}} item>
                            <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="primary"
                                    onClick={() => deleteItem(basket.id)}
                                    >
                                    <DeleteForeverIcon />
                            </IconButton>
                        </Grid>
                </Grid>
            )}
        </Paper>
    );
});

export default BasketItem;