import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import AppRouter from "./components/AppRouter";
import {observer} from "mobx-react-lite";
import NavBar from "./components/NavBar";
import {check} from "./http/userAPI";
import {Context} from ".";
import Footer from "./components/Footer";

const App = observer(() => {
  const {user} = useContext(Context);
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    check().then(data => {
        user.setUser(data);
        user.setRole(data.data.role);
        user.setIsAuth(true);
        user.setBasketCount(localStorage.getItem('basketCount'));
    }).finally(() => setLoading(false)).catch(e => {
      if(e.response.data.basketCount) user.setBasketCount(e.response.data.basketCount);
      if(e.response.data.guest && !localStorage.getItem('guest')) {
        localStorage.setItem('guest', e.response.data.guest);
        user.setGuest(e.response.data.guest);
      }});
}, []);

  if(loading) {
    return(
      <div>Spinner</div>
    );
  }

  return (
    <Router>
      <NavBar/>
      <AppRouter/>
      <Footer/>
    </Router>
  );
});

export default App;
