import {Box, Button, Container, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography} from "@mui/material";
import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Context} from "..";
import BasketItem from "../components/BasketItem";
import PropTypes from 'prop-types';
import {IMaskInput} from 'react-imask';
import {createZakaz, fetchShipping} from "../http/userAPI";
import {useNavigate} from "react-router-dom";
import {BASKET_STATUS_ROUTE} from "../utils/consts";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
      <IMaskInput
        {...other}
        mask="+7 (#00) 000-00-00"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({target: {name: props.name, value}})}
        overwrite
      />
    );
  });
  
  TextMaskCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };


const Basket = observer(() => {
    const {user} = useContext(Context);
    const navigate = useNavigate();

    const [fullPrice, setFullPrice] = useState(0);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [comments, setComments] = useState('');
    const [pay, setPay] = useState('pay_0');
    const error = useRef(false);
    const [errorName, setErrorName] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [errorAddress, setErrorAddress] = useState('');
    const [shipping, setShipping] = useState('');
    //const [load, setLoad] = useState(false);

    const [values, setValues] = React.useState({
        phone: '',
    });

    useEffect(() => {
        if(user.shipping.length === 0) {
            fetchShipping().then(data => user.setShipping(data));
        }
    }, []);

    useEffect(() => {
        if(user.shipping.length === 1){
            setName(user.shipping[0].name);
            setValues({
                phone: user.shipping[0].phone,
            });
            setAddress(user.shipping[0].address);
            setComments(user.shipping[0].comments);
        }
    },[user.shipping]);

    useEffect(() => {
        if(user.basket.length > 0)setFullPrice(user.basket.map(basket => basket.count*basket.price).reduce((price, summ) => price + summ));
    }, [user.basket]);

    const handleChange = (event) => {
        const phoneRGEX = /^\+([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{2}( |-)?[0-9]{2}|[a-zA-Z0-9]{7})$/;
        if(event.target.value.match(phoneRGEX)){
            setErrorPhone('');
        }
        setValues({
          ...values,
          [event.target.name]: event.target.value,
        });
      };

    const addZakaz = () => {
        //включаем лоадинг
        //setLoad(true);
        setErrorName('');
        setErrorPhone('');
        setErrorAddress('');
        error.current = false;
        const phoneRGEX = /^\+([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{2}( |-)?[0-9]{2}|[a-zA-Z0-9]{7})$/;

        if(name.length < 3)
        {
            setErrorName('Заполните поле: как к Вам обратиться?');
            error.current = true;
        }

        if(!values.phone.match(phoneRGEX))
        {
            setErrorPhone('Укажите номер телефона в формате +7 (000) 000-00-00');
            error.current = true;
        }

        if(address.length < 5)
        {
            setErrorAddress('Заполните поле: адрес');
            error.current = true;
        }

        if(!error.current){
            const formData = new FormData();
            formData.append('name', name);
            formData.append('phone', values.phone);
            formData.append('address', address);
            formData.append('comments', comments);
            formData.append('pay', pay);
            formData.append('guest', localStorage.getItem('guest'));
            createZakaz(formData).then(data => {
                if(data.status === "success"){
                    user.setBasket([]);
                    user.setBasketCount(0);
                    navigate(BASKET_STATUS_ROUTE + '/' + data.zakazId);
                    console.log(data);
                }
            }).finally(() => {
                //setLoad(false);
            });
        }
    };

    const shippingChange = (event) => {
        setShipping(event.target.value);
        setName(user.shipping[event.target.value].name);
        setValues({
            phone: user.shipping[event.target.value].phone,
        });
        setAddress(user.shipping[event.target.value].address);
        setComments(user.shipping[event.target.value].comments);
      };

    return(
        <Box sx={{flexGrow: 1}}>
            <Container sx={{maxWidth:{
                    xl: 'xl', lg: 'lg'
                }, p:3}}>
                    <Typography variant="h4">
                        Корзина
                    </Typography>
                    <BasketItem/>

                    {user.basketCount > 0 ?
                    <Box>
                        <Typography variant="h6">
                            Стоимость заказа: {fullPrice} ₽
                        </Typography>
                        <Typography variant="h6" sx={{mt:2}}>
                            Доставка по Вологде
                        </Typography>
                        <FormControl sx={{mt:2,mb:2,width:'100%'}}>
                            {user.shipping.length > 1 ?
                            <FormControl sx={{mb:1}}>
                                <InputLabel color="greenPrimary" id="sipping-select-label">Выбрать адрес из списка</InputLabel>
                                <Select
                                id="sipping-select"
                                labelId="sipping-select-label"
                                label="Выбрать адрес из списка"
                                value={shipping}
                                color="greenPrimary"
                                onChange={shippingChange}
                                >
                                    {user.shipping.map((shipping, i) =>
                                        <MenuItem key={shipping.id} value={i}>{shipping.address}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            :
                            null
                            }
                        <TextField
                            error = {errorName != '' ? true : false}
                            required
                            autoComplete="off"
                            id="name"
                            name="name"
                            value={name}
                            onChange={e => {
                                setName(e.target.value);
                                if(e.target.value.length > 2) setErrorName('');
                            }}
                            label="Как к Вам обратиться?"
                            color="greenPrimary"
                            sx={{minWidth:'350px', width:'100%',mb:1}}
                            helperText={errorName != '' ? errorName : null}
                        />
                        <TextField
                            error = {errorPhone != '' ? true : false}
                            required
                            autoComplete="off"
                            name="phone"
                            id="formatted-phone"
                            label="Телефон для связи"
                            color="greenPrimary"
                            sx={{minWidth:'350px', width:'100%',mb:1}}
                            helperText={errorPhone != '' ? errorPhone : null}
                            InputProps={{
                                inputComponent: TextMaskCustom,
                                value: values.phone,
                                onChange: handleChange
                            }}
                        />
                        <TextField
                            error = {errorAddress != '' ? true : false}
                            required
                            autoComplete="off"
                            id="address"
                            value={address}
                            onChange={e => {
                                setAddress(e.target.value);
                                if(e.target.value.length > 5) setErrorAddress('');
                            }}
                            label="Адрес доставки"
                            color="greenPrimary"
                            name="address"
                            sx={{minWidth:'350px', width:'100%',mb:1}}
                            helperText={errorAddress != '' ? errorAddress : null}
                        />
                        <TextField
                            id="comments"
                            autoComplete="off"
                            name="comments"
                            value={comments}
                            onChange={e => setComments(e.target.value)}
                            label="Комментарий, пожелания"
                            color="greenPrimary"
                            multiline
                            rows={2}
                            sx={{minWidth:'350px', width:'100%'}}
                        />
                        </FormControl>
                        <Typography variant="h6">
                            Способы оплаты
                        </Typography>
                        <FormControl>
                        <RadioGroup
                            defaultValue="pay_0"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel 
                            onChange={e => setPay(e.target.value)}
                            value="pay_0" 
                            control={<Radio color="greenPrimary"/>} 
                            label="Наличными при получении" />
                            <FormControlLabel 
                            onChange={e => setPay(e.target.value)}
                            value="pay_1" 
                            control={<Radio color="greenPrimary"/>} 
                            label="Банковской картой при получении" />
                            <FormControlLabel 
                            onChange={e => setPay(e.target.value)}
                            value="pay_2" 
                            control={<Radio color="greenPrimary"/>} 
                            label="Банковской картой онлайн" />
                        </RadioGroup>
                        </FormControl>
                        <Grid container spacing={1} justifyContent="space-between" sx={{alignItems: 'center',
                        direction:{
                            xs: 'column',
                            md: 'row'
                        }}}>
                            <Grid item xs>
                                <Typography variant="body1">
                                    * поля отмеченные звездочкой, обязательны для заполнения
                                </Typography>
                            </Grid>
                            <Grid item xs="auto" sx={{minWidth:'300px', width:'100%'}}>
                                <Stack alignItems="center" justifyContent="space-between">
                                    <Button 
                                    onClick={addZakaz}
                                    variant="contained" 
                                    color="greenPrimary" 
                                    sx={{width:'100%'}}>
                                        Оформить заказ
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                :
                    <Box>
                        <Typography variant="h6">
                                    В корзине пока нет товаров
                        </Typography>
                    </Box>
                }
            </Container>
            
        </Box>
    );
});
export default Basket;